(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:LastPostponement
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('LastPostponement', LastPostponement);

  function LastPostponement($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/matches/:id/lastPostponement', {id: '@_id'}, {
      update: {method: 'PATCH'}
    })
  }
}());
